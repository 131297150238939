<template>
  <v-layout wrap
    :key="bookingWorkspace"
  >
    <v-flex xs6
      class="activities-index"
    >
      <v-card
        elevation="0"
      >
        <v-card-text class="card-text">
          <custom-table
            :loading="loading"
            :title="$t('sidebar.places', locale)"
            :headers="[
              { text: $t('booking.places.address', locale), value: 'ident' },
              { text: '', value: 'actions', align: 'end' },
            ]"
            :onGetData="handleGetData"
            :tableData="places"
            :onEditItem="handleEdit"
            :onDeleteItem="handleDelete"
            :startIndex="startIndex"
            :itemsPerPage="itemsPerPage"
            :count="count"
            :onSearch="handleSearch"
            :hideAdd="true"
          />
        </v-card-text>
      </v-card>
    </v-flex>
    <v-flex xs6
      style="padding:20px 20px 20px 10px;"
    >
      <v-card
        style="height:calc(100vh - 104px);overflow-y: auto;overflow-x: hidden;scrollbar-width: thin !important;"
        elevation="0"
      >
        <v-card-text>
          <no-edited
            v-if="!editedID"
            :locale="locale"
            :onAdd="handleAddItem"
          />
          <form-place
            v-else
            :editedID="editedID"
            :locale="locale"
            :onClose="handleClose"
          />
        </v-card-text>
      </v-card>
    </v-flex>
  </v-layout>
</template>
<script>
import { mapState } from 'vuex'
import api from '@/services/api'
import utils from '@/services/utils'
import CustomTable from '@/components/customTable/Index'
//import List from './List'
import NoEdited from './NoEdited'
import FormPlace from './FormPlace'
//import FormActivityStepper from './formActivity/formActivityStepper/Index'
export default {
  components: {
    CustomTable,
    NoEdited,
    FormPlace,
  },
  data: () => ({
    loading: false,
    places: [],
    search: '',
    startIndex: 0,
    itemsPerPage: 5,
    count: 0,
    isAssisted: false,
    editedID: null,
    updateData: null,
  }),
  computed: {
    ...mapState('app',['locale', 'bookingWorkspace']),
    workspaceID () {
      return this.bookingWorkspace
    }
  },
  watch: {
    bookingWorkspace () {
      this.handleGetData(0)
    },
  },
  mounted () {
    this.handleGetData()
  },
  methods: {
    handleSearch (v) {
      this.search = v
      this.handleGetData(0)
    },
    handleGetData (startIndex) {
      this.loading = true
      if (startIndex >=0 ) this.startIndex = startIndex
      api.getAll ('booking', `v1/private/workspaces/${this.workspaceID}/places`, this.startIndex, this.itemsPerPage, this.search)
        .then(response => {             
          this.count = response.count
          this.places = response.data.map (item => {
            item.Name = utils.getTranslation(item.Address, this.locale)
            return item
          })
          this.loading = false
        })
    },
    handleEdit (v) {
      this.handleClose()
      this.editedID = v
    },
    handleDelete (v) {
      alert(v)
    },
    handleAddItem () {
      this.editedID = 'new'
    },
    handleClose (action) {
/*      this.updateData ={
        action,
        data,
        LMD: new Date().getTime()
      }*/
      if (action) this.handleGetData (0)
      this.editedID = null
    },
  }
}
</script>
<style scoped>
.activities-index {
  padding: 20px 10px 20px 20px;
  height: calc(100vh - 65px);
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: none !important;
}
.activities-index .card-text {
  padding: 0;
}
</style>

